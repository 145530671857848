<template>
    <LayoutOneColumn :maxWidth="600">
        <v-switch
                v-model="$store.persist.d.isThemeDark"
                label="Dark theme"
                hide-details
        />
<!--        <v-switch-->
<!--                v-model="$store.persist.d.isDevMode"-->
<!--                label="Dev mode"-->
<!--                hide-details-->
<!--        />-->
        <div class="mt-4">
          <v-btn
            @click="importAgentsFromProduction"
            depressed
            color="primary"
          >
            Import agents from production
          </v-btn>
          <v-btn
              @click="importAgents"
              depressed
              color="primary"
              class="ml-2"
          >
            Import agents
          </v-btn>
          <input ref="importInput"
                 type="file"
                 :multiple="false"
                 @change="onSelectedDocument"
                 style="display: none;"
          />
          <v-btn
              @click="exportAgents"
              depressed
              color="primary"
              class="ml-2"
          >
            Export agents
          </v-btn>
        </div>
        <v-btn
                @click="removeLocalData"
                depressed
                color="error"
                class="mt-4"
        >
          Reset local data
        </v-btn>
    </LayoutOneColumn>
</template>

<script>
import LayoutOneColumn from "@/ui/layouts/LayoutOneColumn";

export default {
  name: "PageSettings",
  components: {LayoutOneColumn},
  methods: {
    removeLocalData() {
      this.$api.app.openConfirmDialog({
        title: "Removing local data",
        text: "Are you sure you want to delete data from local storage?",
        ok: () => {
          Object.keys(localStorage)
            .filter((k) => k.startsWith("ai-tool_"))
            .forEach((k) => localStorage.removeItem(k));

          location.href = "/agents";
        },
      });
    },
    importAgents() {
      this.$refs.importInput.click();
    },
    downloadObjectAsJson(exportObj, exportName) {
      const dataStr =
        "data:text/json;charset=utf-8," +
        encodeURIComponent(JSON.stringify(exportObj, null, 2));
      const downloadAnchorNode = document.createElement("a");
      downloadAnchorNode.setAttribute("href", dataStr);
      downloadAnchorNode.setAttribute("download", exportName + ".json");
      document.body.appendChild(downloadAnchorNode); // required for firefox
      downloadAnchorNode.click();
      downloadAnchorNode.remove();
    },
    exportAgents() {
      this.downloadObjectAsJson(this.$store.main.s.agents, "ai-agents");
    },
    onSelectedDocument() {
      const isValidFile =
        this.$refs.importInput.files &&
        this.$refs.importInput.files.length !== 0;
      if (!isValidFile) {
        this.$api.app.snackError("Invalid");
        return;
      }

      const file = this.$refs.importInput.files[0];

      this.$refs.importInput.files = null;
      this.$refs.importInput.name = "";
      this.$refs.importInput.value = null;

      const fr = new FileReader();

      fr.addEventListener("load", () => {
        const data = JSON.parse(fr.result);
        if (data.fields) {
          data.fields = data.fields.map((field) => field.name ? field.name : field); // Изменено здесь
        }
        this.$store.main.s.agents = data;
        this.$store.persist.s.agents = JSON.stringify(
          this.$store.main.s.agents
        );
        location.reload();
      });

      fr.readAsText(file);
    },
    async importAgentsFromProduction() {
      try {
        const agents = [];

        const items = (
          await this.$api.main.postRequest("agents/import-from-production")
        ).data;

        const capitalize = (str) => str.charAt(0).toUpperCase() + str.slice(1);

        const snakeCaseToPascalCase = (str) =>
          str
            .split("-")
            .map((el) => capitalize(el))
            .join(" ");

        Object.entries(items).forEach(([k, v]) => {
          const version = {
            version: "001",
            model: v.model,
            temperature: v.temperature,
            top_p: v.top_p,
            max_tokens: v.max_tokens,
            systemMessage: v.systemMessage,
            messages: v.messages,
          };

          if (v.fields) {
            version.fields = v.fields.map((field) =>
              field.name ? { name: field.name } : { name: field }
            ); // Изменено здесь
            version.playground = Object.fromEntries(
              v.fields.map((field) => [field.name ? field.name : field, ""])
            );
          } else {
            let fields = [];

            const fieldsRE = /{([^{}]+)}/g;

            let fieldsPart = version.systemMessage
              ?.match(fieldsRE)
              ?.map((el) => el.replace("{", "").replace("}", ""));
            if (fieldsPart) {
              fields = [...fields, ...fieldsPart];
            }

            version.messages.forEach((message) => {
              let fieldsPart = message.content
                .match(fieldsRE)
                ?.map((el) => el.replace("{", "").replace("}", ""));
              if (fieldsPart) {
                fields = [...fields, ...fieldsPart];
              }
            });

            if (fields) {
              version.fields = fields.map((field) => ({ name: field })); // Изменено здесь
              version.playground = Object.fromEntries(
                fields.map((field) => [field, ""])
              );
            }
          }

          const id = v.deployPath.split(".")[0];
          agents.push({
            id,
            name: snakeCaseToPascalCase(id),
            oldName: k,
            description: "",
            createdAt: +new Date(),
            updatedAt: +new Date(),
            deployPath: v.deployPath,
            lastDeployedVersion: "001",
            versions: [version],
          });
        });

        console.log(agents);

        // save to store
        this.$store.main.s.agents = agents;
        this.$store.persist.s.agents = JSON.stringify(
          this.$store.main.s.agents
        );

        this.$api.app.snackSuccess("Success");

        setTimeout(() => {
          location.href = "/agents";
        }, 500);
      } catch (e) {
        console.error(e);
        this.$api.app.snackError("Error");
      }
    },
  },
};
</script>
