<template>
  <v-dialog
    v-if="confirmDialog"
    v-model="confirmDialog.model"
    :max-width="confirmDialog.width"
    persistent
  >
    <v-card>
      <v-card-title>{{ confirmDialog.title }}</v-card-title>
      <v-card-text v-html="confirmDialog.text"/>
      <v-card-actions>
        <v-spacer/>
        <v-btn
          text
          @click="confirmDialog.cancel"
        >
          Cancel
        </v-btn>
        <v-btn
          color="primary"
          depressed
          @click="confirmDialog.ok"
        >
          Ok
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'ConfirmDialog',
  computed: {
    confirmDialog() {
      return this.$api.app.getConfirmDialog();
    },
  },
};
</script>

<style scoped>

</style>
