import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/login',
    name: 'Login',
    component: () => import(/* webpackChunkName: "login" */ '../ui/pages/PageLogin.vue'),
  },
  {
    path: '/agents',
    name: 'PageAgents',
    component: () => import(/* webpackChunkName: "agents" */ '../ui/pages/PageAgents.vue'),
    meta: {
      title: 'Agents',
    },
  },

  {
    path: '/agents/:agentId',
    name: 'PageAgent',
    component: () => import(/* webpackChunkName: "agent" */ '../ui/pages/PageAgent.vue'),
    meta: {
      title: 'Agent',
    },
  },

  {
    path: '/benchmark',
    name: 'Benchmark',
    component: () => import(/* webpackChunkName: "benchmark" */ '../ui/pages/PageBenchmark.vue'),
    meta: {
      mainBottomNavigation: true,
      title: 'Benchmark',
    },
  },

  {
    path: '/vision',
    name: 'Vision',
    component: () => import(/* webpackChunkName: "vision" */ '../ui/pages/PageVision.vue'),
    meta: {
      mainBottomNavigation: true,
      title: 'Vision',
    },
  },

  {
    path: '/schema',
    name: 'Schema',
    component: () => import(/* webpackChunkName: "schema" */ '../ui/pages/PageSchema.vue'),
    meta: {
      mainBottomNavigation: true,
      title: 'Schema',
    },
  },

  {
    path: '/dev',
    name: 'Dev',
    component: () => import(/* webpackChunkName: "dev" */ '../ui/pages/PageDev.vue'),
    meta: {
      mainBottomNavigation: true,
      title: 'Dev',
    },
  },

  {
    path: '/',
    redirect: '/agents',
  },
  {
    path: '/*',
    redirect: '/',
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL || '/',
  routes,
});

export default router;
