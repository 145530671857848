import Vue from 'vue';

import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import createVuetify from '@/plugins/vuetify';
import AppPlugin from '@/utils/AppPlugin';

import 'roboto-fontface/css/roboto/roboto-fontface.css';
import '@mdi/font/css/materialdesignicons.css';

import './utils/WindowSize';

import mockAgents from './assets/mockAgents.json';

// patch

if (!window.crypto.randomUUID) {
  window.crypto.randomUUID = () => ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
    (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16));
}

// init data for app

const plugins = [
  new AppPlugin('store', ['app', 'main', 'persist']),
  new AppPlugin('api', ['app', 'main', 'auth', 'agent', 'benchmark'], true),
];

Vue.config.productionTip = false;

// for IDE highlighting
Vue.prototype.$api = null;
Vue.prototype.$store = null;

// Vue.prototype.$keycloak = Keycloak(initOptions);

(async function () {

  await Promise.all(plugins.map(async plugin => {
    Vue.use(plugin);
    await plugin.wait();
  }));

  // init routing logic

  if (process.env.VUE_APP_HAS_AUTH === 'true') {
    router.beforeEach((to, from, next) => {
      if (to.meta?.isExcludedAuth) {
        next();
        return;
      }
      const isAuth = !!router.app.$store.persist.d.authToken;
      if (to.path !== '/login' && !isAuth) {
        next({path: '/login'});
      } else if (to.path === '/login' && isAuth) {
        next({path: '/'});
      } else {
        next();
      }
    });
  }

  // render app

  const app = new Vue({
    router,
    vuetify: createVuetify({isDark: Vue.prototype.$store.persist.d.isThemeDark}),
    watch: {
      '$store.persist.d.isThemeDark': {
        handler(v) {
          app.$vuetify.theme.isDark = v;
        },
      },
      '$route.path'() {
        // TODO: fetch and process data by page opening
      },
    },
    render: h => h(App),
  });

  // init data for debug

  // eslint-disable-next-line no-underscore-dangle
  if (process.env.NODE_ENV === 'development') {
    // eslint-disable-next-line no-underscore-dangle
    window.__app = app;
  }

  // init app data

  plugins.forEach(plugin => {
    plugin.setVueInstance(app);
  });

  app.$store.app.toolbarLoading = true;

  // init request manager

  // Vue.prototype.$rm = new RequestManager(app);

  // mount app

  app.$mount('#app');

  // init app logic

  try {

    try {
      await app.$api.benchmark.init();
    } catch (e) {
      console.error(e);
    }

    // check app version
    if (app.$store.persist.d.version?.toString() !== app.$store.main.d.version?.toString()) {
      app.$api.main.cleanLocalData(); // TODO: change logic after 1.0 release
      app.$store.persist.d.version = app.$store.main.d.version.toString();
      window.location.reload();
    }

    // check auth
    const response = await fetch(`${process.env.VUE_APP_API_URL}/auth/check`, {
      headers: {
        Authorization: app.$store.persist.d.authToken,
      },
    });
    if (response.status === 401) {
      app.$api.auth.signOut();
      app.$router.push('/login').catch(() => {
      });
    }

    /**
     *  если бэкенд был запущен последний раз позже, чем мы кэшировали данные,
     *  то обновляем мета данные, иначе обрабатываем сырые мета данные из кэша.
     */

    // load or mock projects
    if (app.$store.persist.s.agents) {
      try {
        app.$store.main.s.agents = JSON.parse(app.$store.persist.s.agents).sort((a, b) => b.createdAt - a.createdAt)
      } catch (e) {
        console.error(e);
        app.$store.persist.s.agents = JSON.stringify(mockAgents);
        app.$store.main.s.agents = mockAgents.sort((a, b) => b.createdAt - a.createdAt);
      }
    } else {
      app.$store.persist.s.agents = JSON.stringify(mockAgents);
      app.$store.main.s.agents = mockAgents.sort((a, b) => b.createdAt - a.createdAt);
    }

    // benchmark
    // if (app.$store.persist.s.benchmark) {
    //   try {
    //     app.$api.benchmark.benchmark = JSON.parse(app.$store.persist.s.benchmark);
    //   } catch (e) {
    //     console.error(e);
    //   }
    // }
    if (app.$store.persist.s.benchmarkResults) {
      try {
        app.$api.benchmark.benchmarkResults = JSON.parse(app.$store.persist.s.benchmarkResults);
      } catch (e) {
        console.error(e);
      }
    }
    if (app.$store.persist.s.benchmarkTotals) {
      try {
        app.$api.benchmark.benchmarkTotals = JSON.parse(app.$store.persist.s.benchmarkTotals);
      } catch (e) {
        console.error(e);
      }
    }

    // app is ready
    app.$store.main.d.isAppReady = true;

  } catch (e) {
    console.error('Errored starting app');
    console.error(e);
  }
})();
